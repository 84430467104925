<template>
  <div v-if="customer">
    <loading v-if="displayLoading"></loading>

    <div class="page">
      <span class="text-left">
        <b-link
          class="btn"
          :to="{
            name: 'LiffSalesCustomerList',
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
            <path
              d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
            />
          </svg>
          回列表
        </b-link>
      </span>
      <div class="page__title">
        客戶資料
        <button @click="toggleEdit">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
      </div>

      <div class="d-flex align-items-center pl-3 bg-white">
        <b-avatar class="mr-3" :src="`${customer.avatar_url}`"></b-avatar>
        <div class="mr-auto font-weight-bold">
          {{ customer.name }}
          <b-badge
            pill
            variant="light"
            class="text-dark"
            v-for="t in customer.tags"
            :key="t"
          >
            {{ t }}
          </b-badge>
        </div>
        <b-button v-if="false" size="xs" variant="outline-dark"
          >發訊息</b-button
        >
      </div>

      <Tabs
        :tabs="tabs"
        :activeTab="activeTab"
        @updateActiveTab="handleUpdateActiveTab"
      />

      <div v-if="activeTab == 'profile'" class="bg-white">
        <table class="table table-borderless">
          <template>
            <template
              v-for="i in fields.filter((x) => {
                return x.hidden == undefined || !x.hidden;
              })"
              class="field"
            >
              <tr v-if="i.title || i.key" :key="i.key">
                <th
                  v-if="i.title"
                  :class="
                    i.required === true && profile_edit
                      ? 'field--required'
                      : null
                  "
                >
                  {{ i.title }}
                </th>
                <th
                  v-else
                  :class="
                    i.required === true && profile_edit
                      ? 'field--required'
                      : null
                  "
                >
                  {{ i.matched_key }}
                </th>
                <td>
                  <div v-if="profile_edit">
                    <b-form-group content-cols="12" content-cols-lg="12">
                      <div v-if="i.type === 'text'">
                        <b-form-input
                          class="field__input"
                          v-model="customer_profile_module[i.matched_key]"
                          :required="i.required"
                          :placeholder="i.placeholder"
                          :disabled="isFieldDisabled(i)"
                        ></b-form-input>
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>

                      <div v-if="i.type === 'select'">
                        <SharedSelect
                          v-model="customer_profile_module[i.matched_key]"
                          :options="i.options"
                          :hasBorder="false"
                          :placeholder="i.placeholder"
                          :disabled="isFieldDisabled(i)"
                        ></SharedSelect>
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>

                      <div v-if="i.type === 'radio'" class="s-flex flex-wrap">
                        <SharedRadioButton
                          v-for="(option, index) in i.options"
                          v-model="customer_profile_module[i.matched_key]"
                          :id="i.title + index"
                          :key="i.title + index"
                          :value="option.value"
                          :name="i.matched_key"
                          class="px-2 ml-0"
                          :disabled="isFieldDisabled(i)"
                        >
                          {{ option.text }}
                        </SharedRadioButton>
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>

                      <div v-if="i.type === 'checkbox'">
                        <b-form-checkbox-group
                          style="display: inline-grid; padding-left: 30px"
                          v-model="customer_profile_module[i.matched_key]"
                          :options="i.options"
                          :disabled="isFieldDisabled(i)"
                        ></b-form-checkbox-group>
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>

                      <div v-if="i.type === 'date'">
                        <b-form-input
                          class="field__input"
                          v-model="customer_profile_module[i.matched_key]"
                          :required="i.required"
                          :placeholder="i.placeholder"
                          type="date"
                          :disabled="isFieldDisabled(i)"
                        ></b-form-input>
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>

                      <div v-if="i.type === 'address'">
                        <div
                          class="
                            field__select-group
                            mb-3
                            city-selector-set-has-value
                          "
                        >
                          <SharedSelect
                            v-model="
                              customer_profile_module[i.matched_key + '-city']
                            "
                            :hasBorder="false"
                            selectClass="county"
                            :disabled="isFieldDisabled(i)"
                          />
                          <SharedSelect
                            v-model="
                              customer_profile_module[
                                i.matched_key + '-district'
                              ]
                            "
                            :hasBorder="false"
                            selectClass="district"
                            :disabled="isFieldDisabled(i)"
                          />
                        </div>
                        <input
                          class="field__input"
                          v-model="
                            customer_profile_module[i.matched_key + '-address']
                          "
                          :placeholder="i.placeholder"
                          :disabled="isFieldDisabled(i)"
                          :class="{
                            // invalid:
                            //   v$.form.city.$error ||
                            //   v$.form.district.$error ||
                            //   v$.form.address.$error,
                          }"
                        />
                        <div
                          v-if="
                            v$.customer_profile_module[i.matched_key + '-city']
                              .$error ||
                            v$.customer_profile_module[
                              i.matched_key + '-district'
                            ].$error ||
                            v$.customer_profile_module[
                              i.matched_key + '-address'
                            ].$error
                          "
                          class="form__error-msg"
                        >
                          輸入的資料有誤或未填寫，請確認
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div v-else>
                    {{ displayFieldData(i) }}
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <tr>
            <th>客戶備註</th>
            <td>
              <span v-if="!profile_edit" class="text-secondary">{{
                remark
              }}</span>
              <template v-else class="field">
                <b-form-textarea
                  v-model="remark"
                  class="field__input"
                  placeholder="點擊填寫備註"
                  rows="6"
                ></b-form-textarea>
              </template>
            </td>
          </tr>
        </table>
        <div
          class="d-flex justify-content-between p-3 fixed-bottom"
          v-if="profile_edit"
        >
          <SharedButton
            class="outline-dark mr-2"
            @click="
              () => {
                profile_edit = !profile_edit;
              }
            "
            >取消</SharedButton
          >
          <SharedButton class="s-btn-bg-primary" @click="updateCustomer"
            >儲存</SharedButton
          >
        </div>
      </div>

      <div v-else class="description bg-white">
        <table class="table table-borderless">
          <tr>
            <th>方式</th>
            <td>
              <b-row>
                <b-col v-for="i in methodOptions" :key="i" cols="6" class="p-2">
                  <label
                    @click="
                      () => {
                        description.method = i;
                      }
                    "
                  >
                    <svg
                      v-if="description.method == i"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style="fill: #1e88e5"
                    >
                      <path
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style="fill: #ccc"
                    >
                      <path
                        d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                      />
                    </svg>
                    {{ i }}
                  </label>
                </b-col>
              </b-row>
            </td>
          </tr>
          <tr>
            <th>目的</th>
            <td>
              <b-row>
                <b-col
                  v-for="i in purposeOptions"
                  :key="i"
                  cols="6"
                  class="p-2"
                >
                  <label
                    @click="
                      () => {
                        var el = description.purpose;
                        el.includes(i)
                          ? el.splice(el.indexOf(i), 1)
                          : el.push(i);
                      }
                    "
                  >
                    <svg
                      v-if="description.purpose.includes(i)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style="height: 20px; fill: #1e88e5"
                    >
                      <path
                        d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM339.8 211.8C350.7 200.9 350.7 183.1 339.8 172.2C328.9 161.3 311.1 161.3 300.2 172.2L192 280.4L147.8 236.2C136.9 225.3 119.1 225.3 108.2 236.2C97.27 247.1 97.27 264.9 108.2 275.8L172.2 339.8C183.1 350.7 200.9 350.7 211.8 339.8L339.8 211.8z"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style="height: 20px; fill: #ccc"
                    >
                      <path
                        d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 80H64C55.16 80 48 87.16 48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80z"
                      />
                    </svg>
                    {{ i }}
                  </label>
                </b-col>
              </b-row>
            </td>
          </tr>
          <tr v-if="false">
            <th>分店</th>
            <td>
              <b-select>
                <b-select-option>分店名稱</b-select-option>
              </b-select>
            </td>
          </tr>
          <tr>
            <th>說明</th>
            <td>
              <b-form-textarea
                v-model="description.remark"
                placeholder="寫一些工作描述"
                rows="6"
              ></b-form-textarea>
            </td>
          </tr>
        </table>

        <div class="submit p-3 border-top bg-white">
          <div class="d-flex justify-content-between">
            <b-button size="lg" variant="outline-dark mr-3 w-100"
              >取消</b-button
            >
            <b-button size="lg" variant="primary w-100">儲存</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import liff from "@line/liff"
import LiffChecker from "@/utils/LiffChecker";
import _ from "lodash";
import axios from "axios";
import Tabs from "@/components/Page/Liff/Shared/Tabs.vue";
import { isValidJsonArray, transformJsonToString } from "@/utils/jsonHelper";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedRadioButton from "@/components/Page/Liff/Shared/RadioButton";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import TwCitySelector from "tw-city-selector";
import moment from "moment";

export default {
  components: { Tabs, SharedRadioButton, SharedButton, SharedSelect },

  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      buttonLoading: false,
      displayLoading: true,
      activeTab: "profile",
      tabs: [
        { id: "profile", text: "個人資料" },
        // { id: "description", text: "工作描述" },
      ],
      collapsed: true,
      description: {
        method: "拜訪",
        purpose: [],
        branch: null,
        desc: null,
      },
      methodOptions: ["拜訪", "電訪"],
      purposeOptions: ["新戶推廣", "例行拜訪", "案件關懷", "其他"],

      remark: "",
      customer: null,
      profile_mapping: null,
      fields: [],
      customer_profile_module: {},
      profile_edit: false,
    };
  },

  validations() {
    let customer_profile_module = {};

    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];

      // 特殊規則 地址
      if (field.type === "address") {
        customer_profile_module[field.matched_key + "-city"] =
          field.required === true ? { required } : {};
        customer_profile_module[field.matched_key + "-district"]
          ? field.required === true
          : {};
        customer_profile_module[field.matched_key + "-address"]
          ? field.required === true
          : {};
        continue;
      }

      // 一般欄位規則
      if (field.matched_key) {
        customer_profile_module[field.matched_key] =
          field.required === true ? { required } : {};
      }
    }
    return {
      customer_profile_module,
    };
  },

  computed: {
    customerProfiles() {
      return (
        this.customer.profiles &&
        this.customer.profiles.filter((profile) => {
          return this.getDisplayName(profile.key) !== false;
        })
      );
    },
  },
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    isValidJsonArray,
    transformJsonToString,
    async init() {
      let _this = this;

      const orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);

      _this.lineData = await checker.getLineData();

      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${orgCode}/liff/sales/customers/${this.$route.params.id}`;

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${_this.lineData.accessToken}`,
          },
        })
        .then((response) => {
          _this.customer = response.data.data;
          _this.remark = response.data.data.memo ?? null;
          _this.profile_mapping = response.data.meta.profile_mapping;
          let fields = response.data.meta.module_config?.member_fields ?? [];
          // 設定customer_profile_module內容的值
          let customer_profile_module = Object.keys(_this.customer).reduce(
            (acc, key) => {
              if (key !== "profiles") {
                acc[key] = _this.customer[key];
              }
              return acc;
            },
            {}
          );
          Object.keys(_this.customer.profiles).forEach((key) => {
            let value = _this.customer.profiles[key].value;
            // 在這裡處理屬性和值
            if (_this.isValidJsonArray(value)) {
              customer_profile_module[_this.customer.profiles[key].key] =
                JSON.parse(value);
            } else {
              customer_profile_module[_this.customer.profiles[key].key] = value;
            }
          });
          _this.customer_profile_module = customer_profile_module;
          // sort fields & replace extra.
          _this.fields = _.sortBy(fields, "order");
          Object.keys(_this.fields).forEach((key) => {
            if (_this.fields[key]["matched_key"]) {
              _this.fields[key]["matched_key"] = _this.fields[key][
                "matched_key"
              ].replace(/extra./g, "");
            }

            if (_this.fields[key]["type"] == "date") {
              _this.customer_profile_module[_this.fields[key]["matched_key"]] =
                moment(
                  _this.customer_profile_module[
                    _this.fields[key]["matched_key"]
                  ]
                ).format("YYYY-MM-DD");
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });

      _this.displayLoading = false;
    },
    toggleEdit() {
      this.profile_edit = !this.profile_edit;
      if (
        this.profile_edit &&
        this.fields.some((field) => field.type === "address")
      ) {
        new TwCitySelector({
          el: ".city-selector-set-has-value",
          elCounty: ".county",
          elDistrict: ".district",
        });
      }
    },
    getDisplayName(key) {
      let r = this.profile_mapping.find((x) => {
        return x.key == key;
      });
      return r ? r.display_name : false;
    },
    displayFieldData(field) {
      let value = this.customer_profile_module[field.matched_key];
      if (Array.isArray(value)) {
        return value.join(", ");
      } else if (field.type === "date") {
        return moment(value).format("YYYY/MM/DD");
      } else if (field.type === "radio") {
        //console.log( field.options.find(x=>return value))
        let v = field.options.find((x) => x.value == value);
        if (v) return v.text;
        return value;
      } else {
        return value;
      }
    },
    isFieldDisabled(field) {
      // if (field.can_edit == undefined) return true;
      // return !field.can_edit;
      return field.disabled;
    },
    async updateCustomer() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      let _this = this;
      _this.buttonLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.org_code}/liff/sales/customers/${_this.customer.id}`;
      const headers = {
        Authorization: `Bearer ${_this.lineData.accessToken}`,
      };

      await axios
        .put(
          url,
          {
            memo: _this.remark,
            profiles: _this.customer_profile_module,
          },
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.success = true;

            this.$swal.fire({
              title: "資料已送出",
              type: "success",
            });
            return;
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response);

          if (error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          } else {
            alert("錯誤");
          }

          _this.buttonLoading = false;
        });
      _this.profile_edit = false;
    },
    handleUpdateActiveTab(tabID) {
      this.activeTab = tabID;
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  height: 20px;
}

.b-avatar {
  width: 60px;
  height: 60px;
}

.description {
  padding-bottom: 80px;
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.form {
  padding: 20px 10px;
  background: #ffffff;
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}

.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
}

.table td {
  white-space: normal;
}

.fixed-bottom {
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.page {
  padding-bottom: 5rem;
}
</style>
<style lang="scss">
.custom-control-label::before {
  background-color: #ddd;
}
</style>
