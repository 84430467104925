var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customer)?_c('div',[(_vm.displayLoading)?_c('loading'):_vm._e(),_c('div',{staticClass:"page"},[_c('span',{staticClass:"text-left"},[_c('b-link',{staticClass:"btn",attrs:{"to":{
          name: 'LiffSalesCustomerList',
        }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 256 512"}},[_c('path',{attrs:{"d":"M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"}})]),_vm._v(" 回列表 ")])],1),_c('div',{staticClass:"page__title"},[_vm._v(" 客戶資料 "),_c('button',{on:{"click":_vm.toggleEdit}},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"d-flex align-items-center pl-3 bg-white"},[_c('b-avatar',{staticClass:"mr-3",attrs:{"src":("" + (_vm.customer.avatar_url))}}),_c('div',{staticClass:"mr-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.customer.name)+" "),_vm._l((_vm.customer.tags),function(t){return _c('b-badge',{key:t,staticClass:"text-dark",attrs:{"pill":"","variant":"light"}},[_vm._v(" "+_vm._s(t)+" ")])})],2),(false)?_c('b-button',{attrs:{"size":"xs","variant":"outline-dark"}},[_vm._v("發訊息")]):_vm._e()],1),_c('Tabs',{attrs:{"tabs":_vm.tabs,"activeTab":_vm.activeTab},on:{"updateActiveTab":_vm.handleUpdateActiveTab}}),(_vm.activeTab == 'profile')?_c('div',{staticClass:"bg-white"},[_c('table',{staticClass:"table table-borderless"},[[_vm._l((_vm.fields.filter(function (x) {
              return x.hidden == undefined || !x.hidden;
            })),function(i){return [(i.title || i.key)?_c('tr',{key:i.key},[(i.title)?_c('th',{class:i.required === true && _vm.profile_edit
                    ? 'field--required'
                    : null},[_vm._v(" "+_vm._s(i.title)+" ")]):_c('th',{class:i.required === true && _vm.profile_edit
                    ? 'field--required'
                    : null},[_vm._v(" "+_vm._s(i.matched_key)+" ")]),_c('td',[(_vm.profile_edit)?_c('div',[_c('b-form-group',{attrs:{"content-cols":"12","content-cols-lg":"12"}},[(i.type === 'text')?_c('div',[_c('b-form-input',{staticClass:"field__input",attrs:{"required":i.required,"placeholder":i.placeholder,"disabled":_vm.isFieldDisabled(i)},model:{value:(_vm.customer_profile_module[i.matched_key]),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key, $$v)},expression:"customer_profile_module[i.matched_key]"}}),(
                          _vm.v$.customer_profile_module[i.matched_key].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()],1):_vm._e(),(i.type === 'select')?_c('div',[_c('SharedSelect',{attrs:{"options":i.options,"hasBorder":false,"placeholder":i.placeholder,"disabled":_vm.isFieldDisabled(i)},model:{value:(_vm.customer_profile_module[i.matched_key]),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key, $$v)},expression:"customer_profile_module[i.matched_key]"}}),(
                          _vm.v$.customer_profile_module[i.matched_key].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()],1):_vm._e(),(i.type === 'radio')?_c('div',{staticClass:"s-flex flex-wrap"},[_vm._l((i.options),function(option,index){return _c('SharedRadioButton',{key:i.title + index,staticClass:"px-2 ml-0",attrs:{"id":i.title + index,"value":option.value,"name":i.matched_key,"disabled":_vm.isFieldDisabled(i)},model:{value:(_vm.customer_profile_module[i.matched_key]),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key, $$v)},expression:"customer_profile_module[i.matched_key]"}},[_vm._v(" "+_vm._s(option.text)+" ")])}),(
                          _vm.v$.customer_profile_module[i.matched_key].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()],2):_vm._e(),(i.type === 'checkbox')?_c('div',[_c('b-form-checkbox-group',{staticStyle:{"display":"inline-grid","padding-left":"30px"},attrs:{"options":i.options,"disabled":_vm.isFieldDisabled(i)},model:{value:(_vm.customer_profile_module[i.matched_key]),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key, $$v)},expression:"customer_profile_module[i.matched_key]"}}),(
                          _vm.v$.customer_profile_module[i.matched_key].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()],1):_vm._e(),(i.type === 'date')?_c('div',[_c('b-form-input',{staticClass:"field__input",attrs:{"required":i.required,"placeholder":i.placeholder,"type":"date","disabled":_vm.isFieldDisabled(i)},model:{value:(_vm.customer_profile_module[i.matched_key]),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key, $$v)},expression:"customer_profile_module[i.matched_key]"}}),(
                          _vm.v$.customer_profile_module[i.matched_key].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()],1):_vm._e(),(i.type === 'address')?_c('div',[_c('div',{staticClass:"\n                          field__select-group\n                          mb-3\n                          city-selector-set-has-value\n                        "},[_c('SharedSelect',{attrs:{"hasBorder":false,"selectClass":"county","disabled":_vm.isFieldDisabled(i)},model:{value:(
                            _vm.customer_profile_module[i.matched_key + '-city']
                          ),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, i.matched_key + '-city', $$v)},expression:"\n                            customer_profile_module[i.matched_key + '-city']\n                          "}}),_c('SharedSelect',{attrs:{"hasBorder":false,"selectClass":"district","disabled":_vm.isFieldDisabled(i)},model:{value:(
                            _vm.customer_profile_module[
                              i.matched_key + '-district'
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.customer_profile_module, 
                              i.matched_key + '-district'
                            , $$v)},expression:"\n                            customer_profile_module[\n                              i.matched_key + '-district'\n                            ]\n                          "}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.customer_profile_module[i.matched_key + '-address']
                        ),expression:"\n                          customer_profile_module[i.matched_key + '-address']\n                        "}],staticClass:"field__input",class:{
                          // invalid:
                          //   v$.form.city.$error ||
                          //   v$.form.district.$error ||
                          //   v$.form.address.$error,
                        },attrs:{"placeholder":i.placeholder,"disabled":_vm.isFieldDisabled(i)},domProps:{"value":(
                          _vm.customer_profile_module[i.matched_key + '-address']
                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer_profile_module, i.matched_key + '-address', $event.target.value)}}}),(
                          _vm.v$.customer_profile_module[i.matched_key + '-city']
                            .$error ||
                          _vm.v$.customer_profile_module[
                            i.matched_key + '-district'
                          ].$error ||
                          _vm.v$.customer_profile_module[
                            i.matched_key + '-address'
                          ].$error
                        )?_c('div',{staticClass:"form__error-msg"},[_vm._v(" 輸入的資料有誤或未填寫，請確認 ")]):_vm._e()]):_vm._e()])],1):_c('div',[_vm._v(" "+_vm._s(_vm.displayFieldData(i))+" ")])])]):_vm._e()]})],_c('tr',[_c('th',[_vm._v("客戶備註")]),_c('td',[(!_vm.profile_edit)?_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.remark))]):[_c('b-form-textarea',{staticClass:"field__input",attrs:{"placeholder":"點擊填寫備註","rows":"6"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})]],2)])],2),(_vm.profile_edit)?_c('div',{staticClass:"d-flex justify-content-between p-3 fixed-bottom"},[_c('SharedButton',{staticClass:"outline-dark mr-2",on:{"click":function () {
              _vm.profile_edit = !_vm.profile_edit;
            }}},[_vm._v("取消")]),_c('SharedButton',{staticClass:"s-btn-bg-primary",on:{"click":_vm.updateCustomer}},[_vm._v("儲存")])],1):_vm._e()]):_c('div',{staticClass:"description bg-white"},[_c('table',{staticClass:"table table-borderless"},[_c('tr',[_c('th',[_vm._v("方式")]),_c('td',[_c('b-row',_vm._l((_vm.methodOptions),function(i){return _c('b-col',{key:i,staticClass:"p-2",attrs:{"cols":"6"}},[_c('label',{on:{"click":function () {
                      _vm.description.method = i;
                    }}},[(_vm.description.method == i)?_c('svg',{staticStyle:{"fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"}})]):_c('svg',{staticStyle:{"fill":"#ccc"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"}})]),_vm._v(" "+_vm._s(i)+" ")])])}),1)],1)]),_c('tr',[_c('th',[_vm._v("目的")]),_c('td',[_c('b-row',_vm._l((_vm.purposeOptions),function(i){return _c('b-col',{key:i,staticClass:"p-2",attrs:{"cols":"6"}},[_c('label',{on:{"click":function () {
                      var el = _vm.description.purpose;
                      el.includes(i)
                        ? el.splice(el.indexOf(i), 1)
                        : el.push(i);
                    }}},[(_vm.description.purpose.includes(i))?_c('svg',{staticStyle:{"height":"20px","fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM339.8 211.8C350.7 200.9 350.7 183.1 339.8 172.2C328.9 161.3 311.1 161.3 300.2 172.2L192 280.4L147.8 236.2C136.9 225.3 119.1 225.3 108.2 236.2C97.27 247.1 97.27 264.9 108.2 275.8L172.2 339.8C183.1 350.7 200.9 350.7 211.8 339.8L339.8 211.8z"}})]):_c('svg',{staticStyle:{"height":"20px","fill":"#ccc"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 80H64C55.16 80 48 87.16 48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80z"}})]),_vm._v(" "+_vm._s(i)+" ")])])}),1)],1)]),(false)?_c('tr',[_c('th',[_vm._v("分店")]),_c('td',[_c('b-select',[_c('b-select-option',[_vm._v("分店名稱")])],1)],1)]):_vm._e(),_c('tr',[_c('th',[_vm._v("說明")]),_c('td',[_c('b-form-textarea',{attrs:{"placeholder":"寫一些工作描述","rows":"6"},model:{value:(_vm.description.remark),callback:function ($$v) {_vm.$set(_vm.description, "remark", $$v)},expression:"description.remark"}})],1)])]),_c('div',{staticClass:"submit p-3 border-top bg-white"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"size":"lg","variant":"outline-dark mr-3 w-100"}},[_vm._v("取消")]),_c('b-button',{attrs:{"size":"lg","variant":"primary w-100"}},[_vm._v("儲存")])],1)])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }