<template>
  <div class="s-tabs">
    <div
      class="s-tab"
      v-for="tab in tabs"
      :key="tab.id"
      :class="{
        's-tab--active': tab.id === activeTab,
      }"
      @click="handleClickTab(tab)"
    >
      {{ tab.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => ({
        id: "",
        text: "",
      }),
    },
    activeTab: {
      type: String,
    },
  },
  methods: {
    handleClickTab(tab) {
      this.$emit("updateActiveTab", tab.id);
    },
  },
};
</script>

<style lang="scss">
.s-tabs {
  display: flex;
  background-color: var(--s-white);
  .s-tab {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid #e5e5ea;
  }

  .s-tab--active {
    border-bottom: 2px solid var(--s-primary);
    color: var(--s-primary);
  }
}
</style>
